import React from 'react'
import Template from '../components/Template'

const NotFoundPage = () => (
  <Template>
    <div
      style={{
        backgroundColor: 'white',
        padding: 20 + 'px',
        paddingTop: 200 + 'px',
        minHeight: 73 + 'vh',
        textAlign: 'center',
        width: 100 + '%',
      }}
    >
      <h1
        style={{
          color: '#258bf3',
          fontSize: '80px',
          fontFamily: '-apple-system, Helvetica, sans-serif',
        }}
      >
        404
      </h1>
      <p
        style={{
          color: 'gray',
          fontFamily: '-apple-system, Helvetica, sans-serif',
        }}
      >
        Bork!
        <br />
        Sorry, that page doesn't exist.
      </p>
    </div>
  </Template>
)

export default NotFoundPage
